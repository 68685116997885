.root:global(.ant-input) {
  height: var(--ant-control-height);
  --ant-color-text: theme('colors.ant.text-gray');
}

@screen sm {
  .root:global(.ant-input) {
    height: var(--ant-control-height-lg);

    &:global(.ant-input-css-var) {
      --ant-input-input-font-size: var(--ant-input-input-font-size-lg);
    }
  }
}
