@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --ant-color-primary: #00b0f0;
    --ant-color-text-grey: #1b1b1bb3; //rgb(27 27 27 / 70%);
  }
  html,
  body {
    @apply relative max-w-[100vw] overflow-x-hidden scroll-smooth font-poppins antialiased;
  }

  hr {
    @apply my-4 border border-ant-primary;
  }
}
