@import '@/styles/utils.scss';

.root:global(.ant-form) {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  padding-block: 1rem 2rem;
  padding-inline: var(--card-content-inline-padding);

  > button {
    width: -webkit-fill-available;
    margin-inline: 2.5rem;
  }

  :global {
    .ant-checkbox-wrapper {
      --ant-line-height: 1;
      font-weight: 600;
      color: var(--color-text-grey);
      align-items: center;

      .ant-btn {
        --ant-button-padding-inline: 0.25rem;
      }
    }

    .ant-form-item-explain {
      padding-left: 1rem;
    }
  }
}
