.root:global(.ant-steps) {
  @apply mx-auto mb-4 max-w-xl;

  :global {
    .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: theme('colors.ant.primary') !important;
    }
    .ant-steps-item-icon {
      --ant-steps-icon-size: 32px;
      --ant-steps-icon-font-size: 14px;
      --ant-steps-wait-icon-bg-color: transparent;
      --ant-steps-wait-icon-color: theme('colors.ant.primary');
      --ant-steps-wait-icon-border-color: theme('colors.ant.primary');
      font-weight: 700;
    }
    .ant-steps-item-tail {
      --ant-steps-icon-size: 32px;
      --ant-padding-lg: 26px;
    }
    .ant-steps-item-content {
      --ant-steps-icon-size: 32px;
    }
    .ant-steps-item-title {
      @apply font-medium max-sm:text-xs;
      @apply text-ant-text-gray #{!important};
    }
    .ant-steps-item-active {
      .ant-steps-item-title {
        @apply font-bold;
      }
    }
  }
}

@screen sm {
  .root:global(.ant-steps) {
    :global {
      .ant-steps-item-icon {
        --ant-steps-icon-size: 60px;
        --ant-steps-icon-font-size: 24px;
      }
      .ant-steps-item-tail {
        --ant-steps-icon-size: 60px;
        --ant-padding-lg: 50px;
      }
      .ant-steps-item-content {
        --ant-steps-icon-size: 60px;
      }
    }
  }
}
