.root {
  @apply space-y-8 text-ant-text-gray;

  section {
    @apply space-y-6;
  }
}

.info {
  @apply grid gap-4;
}

.summary {
  @apply space-y-2 text-right;
}

@screen md {
  .root {
    @apply space-y-12;
  }
  .info {
    @apply grid-cols-2 gap-14;
  }
}
