.root:global(.ant-pagination) {
  @apply flex justify-center;
  @apply max-sm:text-sm #{!important};
  max-width: calc(100dvw - 4rem);

  --ant-margin: 2.5rem;
  --ant-font-size: 12px;
  --ant-font-family: theme('fontFamily.poppins');
  --ant-border-radius: theme('borderRadius.full');
  --ant-color-text: theme('colors.ant.text-gray');
  --ant-pagination-item-size: 24px;

  @apply items-center;

  :global {
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
      @apply transition;
      --ant-font-weight-strong: 400;
      font-family: var(--ant-font-family);
      font-weight: var(--ant-font-weight-strong);
      font-size: var(--ant-font-size);
      border-radius: var(--ant-border-radius);
      width: var(--ant-pagination-item-size);
      height: var(--ant-pagination-item-size);
      line-height: var(--ant-pagination-item-size);
      color: var(--ant-color-text);

      a,
      button {
        @apply transition;
        --ant-font-weight-strong: 400;
        font-family: var(--ant-font-family);
        font-weight: var(--ant-font-weight-strong);
        font-size: var(--ant-font-size);
        border-radius: var(--ant-border-radius);
        width: var(--ant-pagination-item-size);
        height: var(--ant-pagination-item-size);
        line-height: var(--ant-pagination-item-size);
        color: var(--ant-color-text);
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      a,
      button {
        --ant-color-text: theme('colors.ant.primary');
        --ant-color-text-disabled: theme('colors.ant.primary');
      }
    }

    .ant-pagination-item-active {
      @apply border-0 bg-ant-primary text-white;

      > a {
        @apply text-current;
      }

      &:hover {
        @apply ring-4 ring-ant-primary/25;

        > a {
          @apply text-current;
        }
      }
    }
  }
}

@screen sm {
  .root:global(.ant-pagination) {
    --ant-font-size: 18px;
    --ant-pagination-item-size: 42px;
  }
}
