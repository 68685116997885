.root {
  @apply relative ml-auto grid;
}

.dialog {
  @apply fixed top-24 z-1 h-fill min-h-96 w-full bg-white p-2;
}

@screen md {
  .dialog {
    @apply absolute top-[calc(100%_+_0.5rem)] z-1 h-fit min-h-96 w-80 rounded-xl bg-white p-3 ring-1 ring-ant-primary;
  }
}
