.root:global(.ant-form-item) {
  @apply *:relative;

  &:global(.ant-css-var) {
  }
  &:global(.ant-form-css-var) {
    --ant-form-item-margin-bottom: 1rem;
  }

  :global {
    .ant-form-item-label {
      --ant-font-size: 16px;
      --ant-form-label-font-size: 16px;
    }
    .ant-form-item-control-input {
      & + div > *:empty {
        display: none;
      }
    }
    .ant-form-item-explain,
    .ant-form-item-extra {
      @apply mx-2 mt-1.5;
      --ant-font-size: 12px;
    }
    .ant-form-item-margin-offset {
      @apply mb-0 #{!important};
    }
    .ant-input-group,
    .ant-input-affix-wrapper {
      height: var(--ant-control-height);
      --ant-input-input-font-size: 12px;

      input {
        @apply h-full;
      }
    }
  }
}

@screen sm {
  .root:global(.ant-form-item) {
    &:global(.ant-form-css-var) {
      --ant-form-item-margin-bottom: 1.25rem;
    }

    :global {
      .ant-form-item-label {
        --ant-font-size: 22px;
        --ant-form-label-font-size: 22px;
      }
      .ant-input-group,
      .ant-input-affix-wrapper {
        height: var(--ant-control-height-lg);
        --ant-input-input-font-size: var(--ant-input-input-font-size-lg);
      }
    }
  }
}
