.root {
  @apply relative isolate flex text-base font-bold text-ant-text-gray;

  &::after {
    content: '';
    @apply absolute left-0 top-1/2 -z-1 w-full border-b border-dashed border-ant-primary;
  }

  & > figure {
    @apply inline-flex w-fit gap-2 bg-white box-decoration-clone py-1 pr-1;
  }
}

@screen sm {
  .root {
    @apply text-[22px];
  }
}
