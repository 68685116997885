.root:global(.ant-picker) {
  height: var(--ant-control-height);
  --ant-color-text: theme('colors.ant.text-gray');
  --ant-font-size: 12px;
}

@screen sm {
  .root:global(.ant-picker) {
    height: var(--ant-control-height-lg);
    --ant-font-size: 16px;

    &:global(.ant-picker-css-var) {
      --ant-date-picker-input-font-size: var(--ant-date-picker-input-font-size-lg);
    }
  }
}
