.root:global(.ant-table-wrapper) {
  :global {
    .ant-table {
      --ant-font-family: theme('fontFamily.poppins');
      --ant-color-text: theme('colors.ant.text-gray');
      --ant-table-header-color: theme('colors.ant.text-gray');
      --ant-table-cell-font-size: 15px;
    }

    .ant-table-cell {
      @apply text-center;

      // &.ant-table-cell-row-hover {
      //   --ant-table-row-hover-bg: theme('colors.ant.primary/.10');
      // }
    }
  }
}

.root.striped:global(.ant-table-wrapper) {
  :global {
    .ant-table-row {
      &:nth-child(even) {
        @apply bg-ant-primary/5;
      }
    }
  }
}
