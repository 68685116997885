.root {
  @apply my-4 block space-y-2 text-center;

  > h1 {
    @apply text-xl font-bold text-ant-text-gray;
  }
  > strong {
    @apply block text-base font-bold text-ant-primary;
  }
  > sub {
    @apply block text-sm font-semibold text-ant-text-gray;
  }
}

@screen sm {
  .root {
    @apply mb-12 space-y-4;

    > h1 {
      @apply text-5xl;
    }
    > strong {
      @apply text-lg;
    }
    > sub {
      @apply text-base;
    }
  }
}
