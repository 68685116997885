.root:global(.ant-input-number) {
  @apply w-[9ch] overflow-hidden font-bold text-ant-text-gray;
  height: var(--ant-control-height);

  &:global(.ant-input-number-css-var) {
    --ant-font-size-lg: 18px;
    --ant-input-number-input-font-size: 18px;
    --ant-input-number-input-font-size-lg: 18px;
  }

  :global {
    .ant-input-number-input-wrap {
      @apply h-full;
    }
    input.ant-input-number-input {
      @apply h-full text-center text-ant-text-gray;
      padding: 0.35rem 1.25rem 0.35rem 0.5rem;
    }
    // .ant-input-number-group-addon {
    //   --ant-line-width: 0;
    //   --ant-input-number-padding-inline: 0;

    //   @apply block w-fit #{!important};
    // }
    .ant-input-number-handler-wrap {
      @apply h-full w-6 opacity-100;
    }
    .ant-input-number-handler {
      --ant-line-width: 0;
      --ant-border-radius: 0;
      --ant-border-radius-lg: 0;

      &:hover {
        @apply h-2/5 bg-ant-primary text-white #{!important};
      }
    }
  }
}

@screen sm {
  .root:global(.ant-input-number) {
    height: var(--ant-control-height-lg);

    &:global(.ant-input-number-css-var) {
      --ant-font-size: var(--ant-font-size-lg);
      --ant-input-number-input-font-size: var(--ant-input-number-input-font-size-lg);
    }

    :global {
      .ant-input-number-handler-wrap {
        @apply w-8;
      }
      input.ant-input-number-input {
        @apply w-full pr-8;
      }
    }
  }
}
