.root:global(.ant-popover) {
  @apply max-w-96;
  --ant-color-text: white;

  :global {
    .ant-popover-inner {
      --ant-color-bg-elevated: theme('colors.ant.orange');
    }
    .ant-popover-arrow {
      &:before,
      &:after {
        @apply bg-ant-orange;
      }
    }
  }
}

.icon {
  @apply flex cursor-help items-center rounded-full p-1.5 text-ant-orange;

  :global {
    .anticon {
      @apply text-2xl text-ant-orange;
    }
  }
}
