.main {
  @apply container mb-8 flex flex-col p-4;
}

.auth {
  @apply flex w-full flex-col items-center bg-white max-sm:min-h-screen;
  --card-content-inline-padding: 2rem;
}

@screen sm {
  .auth {
    @apply max-w-4xl rounded-[10px] px-[6.25rem] py-12;
  }
}
@screen md {
  .main {
    @apply my-10 p-6;
  }
}
