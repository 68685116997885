.root:global(.ant-select) {
  &:global(.ant-select-css-var) {
    height: var(--ant-control-height);
  }
}

@screen sm {
  .root:global(.ant-select) {
    --ant-control-height: var(--ant-control-height-lg, 52px);

    &:global(.ant-select-css-var) {
      --ant-control-height: var(--ant-control-height-lg, 52px);
      height: var(--ant-control-height);
    }
  }
}
