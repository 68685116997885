.root {
  @apply relative grid gap-3 text-ant-text-gray;
  grid-template-columns: auto auto 1fr 1fr;

  > :global(.ant-select) {
    grid-column: 1 / span 2;
    grid-row: 2;
  }

  &:not(:last-child) {
    @apply border-b border-ant-grey pb-4;
  }
}
.image {
  @apply max-h-40 max-w-40 object-contain; // bg-ant-primary/15;
}
.details {
  @apply space-y-1;
  grid-column: 2 / span 3;

  > h3 {
    @apply font-bold;
  }
  > p {
    @apply font-semibold;

    // > label {
    //   @apply w-min;
    // }
    > span {
      @apply text-ant-primary;
    }
  }
}
.price {
  @apply w-[12ch] text-center text-2xl font-semibold;

  grid-column: 3 / -1;
}
.remove {
  @apply text-xs font-medium text-ant-red;

  grid-column: 1 / -1;
  grid-row: 3;
}
.checkout_price {
  @apply text-2xl font-semibold text-ant-primary max-sm:col-start-2;
}

@screen sm {
  .root {
    @apply grid-flow-row items-center gap-8;
    grid-template-columns: auto 1fr auto auto auto;

    > :global(.ant-select) {
      // @apply self-end;
      grid-column: unset;
      grid-row: unset;
    }
  }

  .details {
    grid-column: unset;

    > h3 {
      @apply mb-auto;
    }
  }
  .price {
    @apply text-2xl;
    // @apply self-end;
    grid-column: unset;
  }
  .remove {
    @apply absolute right-0 top-0;
    grid-column: unset;
    grid-row: unset;
  }
  .checkout_price {
    @apply mr-16 text-3xl;
  }
}
