.root:global(.ant-radio-wrapper) {
  --ant-color-text: theme('colors.ant.text-gray');

  :global {
    .ant-radio-inner {
      --ant-radio-dot-size: 10;
      --ant-radio-radio-size: 20;
      --ant-radio-radio-bg-color: transparent;
      &::after {
        --ant-radio-radio-color: theme('colors.ant.primary');
      }
    }
  }
}

@screen sm {
  .root:global(.ant-radio-wrapper) {
    @apply text-xl;
    :global {
      .ant-radio-inner {
        --ant-radio-dot-size: 15;
        --ant-radio-radio-size: 25;
      }
    }
  }
}
