.root:global(.ant-form) {
  @apply grid w-full grid-cols-1 justify-center;
  max-width: 400px;
  padding-block: 1rem 2rem;
  padding-inline: var(--card-content-inline-padding);

  > button {
    @apply mx-10;
  }
}
